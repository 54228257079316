<template>
  <section>
    <section>

      <pm-Breadcrumb :home="home" :model="items_bread" />
     <div class="row">
        <div class="col-md-10">
          <h2 class="titulo-links-escolha">Presenças</h2>
        </div>
        <div class="col-md-2" >
        </div>
      </div>
      <div class="row">

        <div v-if="funcaoDoUsuario == 1 || funcaoDoUsuario == 2" :class="'col-md-12'" style="margin-top:20px;">
            <div class="row" v-if="acesso.visualizar">
                <div :class="'col-md-3'">
                  <div class="pm-card">
                    <div class="col-md-12" style="margin-bottom:20px;">
                      <label for="escola"> Selecione uma escola</label>
                      <select class="p-inputtext p-component" v-model="escola_id" @change="limparCampos()"
                        style="appearance: revert !important;width: 100% !important;" >
                          <option  value="" disabled selected> -- Escolha uma Escola -- </option>
                          <option v-for="n in escolas" :key="n" :value="n.id" >{{n.nome}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div :class="'col-md-3'" v-if="escola_id">
                  <div class="pm-card">
                    <div class="col-md-12" style="margin-bottom:20px;">
                      <label for="escola"  value="" disabled selected> Selecione um segmento</label>
                      <select class="p-inputtext p-component" v-model="segmentoInfo"
                        style="appearance: revert !important;width: 100% !important;" @change="listaSerie(segmentoInfo)">
                          <option value="" disabled selected> -- Escolha um Segmento -- </option>
                          <option v-for="s in segmentoALL" :key="s" :value="s.id" >{{s.nome}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div :class="'col-md-3'" v-if="segmentoInfo">
                  <div class="pm-card">
                    <div class="col-md-12" style="margin-bottom:20px;">
                      <label for="escola"  value="" disabled selected> Selecione um período</label>
                      <select class="p-inputtext p-component" v-model="serieInfo"
                        style="appearance: revert !important;width: 100% !important;" @change="listaTurmas()" >
                          <option value="" disabled selected> -- Escolha um Período -- </option>
                          <option v-for="a in serieALL" :key="a" :value="a.id" >{{a.nome}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div :class="'col-md-3'" v-if="serieInfo">
                  <div class="pm-card">
                    <div class="col-md-12" style="margin-bottom:20px;">
                      <label for="escola"  value="" disabled selected> Selecione uma turma</label>
                      <select class="p-inputtext p-component" v-model="turma_id" @change="turma_disciplinas(turma_id),listarAlunosdaTurma(),listaDisciplina();buscarPresencasDisciplina();buscarProfessores();/*buscarTurma(escola_id,'')*/"
                        style="appearance: revert !important;width: 100% !important;" >
                          <option  value="" disabled selected> -- Escolha uma Turma -- </option>
                          <option v-for="n in turmaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="col-md-3" v-if="turma_id != null && mesAtual != 1">
                  <div class="pm-card">
                    <pm-Button  class="p-button-primary"
                        @click="openModalAutorizacao()"
                        label="Liberar mês anterior"
                    />
                  &nbsp;
                    <va-popover
                      placement="right"
                      color="primary"
                      title="Informação do módulo atual"
                      message="Caso algum professor não tenha lançado as informações de presenças para algum mês anterior, é possível autorizá-lo para fazer!"
                      >
                      <i class="pi pi-question-circle" style="font-size: 1.5rem"></i>
                    </va-popover>
                  </div>
                </div>

            </div>
        </div>
        <div v-if="(funcaoDoUsuario == 1 || funcaoDoUsuario == 2) && carregandoPagina" class="col-md-12" style="text-align: center;padding-top: 255px;">
            <pm-ProgressSpinner />
        </div>

        <div class="col-md-12" v-if="!carregandoPagina && acesso.visualizar">
          <section>
            <div class="pm-card">
              <div class="row">
                <div class="col-md-12">
                  <h2 class="titulo-links-escolha" style="margin-top: 0px !important;">
                    Alunos(as)
                  </h2>
                </div>
              </div>
              <div class="row" v-if="turma_id">
                <!--<div class="col-md-3" v-if="this.tipo_segmento!=1 && this.tipo_segmento!=0 || selectSerie.possui_mais_de_um_professor">-->
                <div class="col-md-3" v-if="this.tipo_segmento == 2  || (this.tipo_segmento == 3 && selectSerie.tipo == 2) || selectSerie.possui_mais_de_um_professor">
                  <label>Selecione uma Disciplina:</label>
                  <select class="p-inputtext p-component"
                    v-model="disciplina_id" @change="buscarPresencasDisciplina()"
                    style="appearance: revert !important;width: 100% !important;"
                  >
                    <!--<option  v-if="mes == 0">Selecione um mês</option>-->
                    <option   :value="n.id" v-for="n in disciplina" :key="n.id">{{n.nome}}</option>
                  </select>
                </div>
                <div class="col-md-12" style="margin-top:25px;">
                  <!--<selectCalendario2  v-if="(this.tipo_segmento!=1 && this.tipo_segmento!=0 && disciplina_id!=0) || (disciplina_id!=0 && selectSerie.possui_mais_de_um_professor)"
                    @exibirTabela="exibirTabela"
                    @test="montarCalendarioProfessor" :mes_nome="this.mes_exib_selec" :mes="this.mes_selec"
                    :tabelaDias="tabelaDias" :mesNumero="mes_numeral"
                    :diaSelecionado="this.info.dia" @dialogAction="dialogAction"
                  />-->
                  <selectCalendario2  v-if="((this.tipo_segmento!=1 && this.tipo_segmento != 0 ) || ( selectSerie.possui_mais_de_um_professor)) && disciplina_id != 0"
                    @exibirTabela="exibirTabela"
                    @test="montarCalendarioProfessor" :mes_nome="this.mes_exib_selec" :mes="this.mes_selec"
                    :tabelaDias="tabelaDias" :mesNumero="mes_numeral"
                    :diaSelecionado="this.info.dia" @dialogAction="dialogAction"
                  />
                  <selectCalendario2 v-if="(this.tipo_segmento == 1 || this.tipo_segmento == 0) && !selectSerie.possui_mais_de_um_professor  && disciplina_id != 0"
                    @exibirTabela="exibirTabela"
                    @test="montarCalendarioProfessor" :mes_nome="this.mes_exib_selec" :mes="this.mes_selec"
                    :tabelaDias="tabelaDias" :mesNumero="mes_numeral"
                    :diaSelecionado="this.info.dia" @dialogAction="dialogAction"
                  />
                </div>
                <div class="col-md-12" style="margin-top:10px;"></div>
              </div>
              <div v-if="presencasAlunosView">
                <div v-if="abrirComponete && turma_id && disciplina_id != 0">
                  <CalendarioVisualizar @test="montarCalendarioProfessor"
                    :acesso="acesso" :presencasAlunosView="presencasAlunosView"
                    :tabelaDias="tabelaDias" :mes_nome="this.mes_exib_selec"
                    :mes_sel="this.mes_selec" :mes="this.mes_exib_selec"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <pm-Dialog header="Enviar presença" v-model:visible="displayMaximizable" :style="{width: '100vw'}" :maximizable="true" :modal="true">
        <div class="field col-12" v-if="this.info.dataSelecionada !=''">
          <div>
            <div class="row">
              <div class="col-md-12" v-if="info.dataSelecionada">
                <h2 class="text-01-presenca">
                  Data selecionada: <b>{{info.dataSelecionada}}</b> - envio de presença do dia para a disciplina de <b>{{disciplina_nome}}</b>
                </h2>
              </div>
              <div class="field col-12 md:col-3 lg:col-3" v-if="presenca_infinita == 1">
                <label>Quantidade de presenças</label>
                <select class="p-inputtext p-component" v-model="qdt_presenca"
                  style="appearance: revert !important;width: 100% !important;" >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                </select>
              </div>
            </div>
            <div class="p-fluid formgrid grid" >
                <div class="row">
                  <div class="row" v-if="selectable && info.dia" style="margin-top:5px;">
                    <h4 class="title-presenca-table">Alunos da Turma  </h4>
                    <table-prof-presenca01 @marcarPresenca="marcarPresenca" :alunos="alunos" :acesso="acesso" :st_segmento="this.segmentoInfo"
                        :etapaDeEnvioDePresenca="etapaDeEnvioDePresenca" />
                  </div>

                  <div class='row' v-if="this.selectable &&  info.dia" style="left:0px;width:133px;">
                    <va-inner-loading class=" py-3" style="width: 100%;" :loading="isLoading">
                      <button class="btn btn-primary" @click="presencaEnviar()" v-if="acesso.cadastrar && this.alunos.length">Enviar Presença</button>
                    </va-inner-loading>
                  </div>

                  <div v-if="editarOk && (this.tipo_segmento == 1 || this.serie_tipo == 1)">
                    <editarFundamental1 @marcarPresenca="marcarPresenca" :alunos="alunos" @EditarPresencaDaTurma="EditarPresencaDaTurma" @fecharEditarFundamentalUm="fecharEditarFundamentalUm"/>
                  </div>

                  <div v-if="editarOk && (this.tipo_segmento == 2 || this.serie_tipo == 2)">
                    <editarFundamental2 @marcarPresencaFundamentalDois="marcarPresencaFundamentalDois" :alunos="alunos" @EditarPresencaDaTurma="EditarPresencaDaTurma" @fecharEditarFundamentalUm="fecharEditarFundamentalUm"/>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <template #footer>
        </template>
      </pm-Dialog>


      <pm-Dialog  v-if="funcaoDoUsuario == 1 || funcaoDoUsuario == 2" header="Liberar autorização" v-model:visible="displayMaximizableEdit2"  :maximizable="true">
            <pm-Button title="Editar" type="button"  @click="cadastrarInfo()" label="Cadastrar info"
                  icon="pi pi-file" class="p-button-primary p-button-sm btn-color;table_style" style="margin-left:5px;margin-bottom: 5px;">
            </pm-Button>
            <div class="col-md-12"></div>
            <pm-DataTable  :value="listaAutorizacaoALL"   dataKey="id" :rowHover="true" filterDisplay="menu"
            responsiveLayout="scroll">
            <pm-Column field="data"  header="Nome" style="font-size: 14px;vertical-align:middle !important">
              <template #body="{data}">
                <div>
                    {{data.nome}}
                </div>
              </template>
            </pm-Column>
            <pm-Column field="data"  header="Mês que foi liberado" style="font-size: 14px;vertical-align:middle !important">
              <template #body="{data}">
                <div>
                    {{data.mes}}
                </div>
              </template>
            </pm-Column>
            <pm-Column field="data"  header="Quantidade de dias liberados" style="font-size: 14px;vertical-align:middle !important">
              <template #body="{data}">
                <div>
                    {{data.qdt_dias}} dias
                </div>
              </template>
            </pm-Column>
            <pm-Column field="data" header="Ações" style="font-size: 14px;vertical-align:middle !important">
              <template #body="{data}" >
                <pm-Button title="Editar" type="button"  @click="editarAutori(data)" label="Editar info"
                    icon="pi pi-pencil" class="p-button-primary p-button-sm btn-color;table_style" style="margin-left:5px">
                </pm-Button>
                <pm-Button title="Apagar" type="button"  @click="apagarAutorizacao(data)" label="Apagar"
                    icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color;table_style" style="margin-left:5px">
                </pm-Button>
              </template>
            </pm-Column>
          </pm-DataTable>
      </pm-Dialog>

      <pm-Dialog v-if="funcaoDoUsuario == 1 || funcaoDoUsuario == 2" header="Liberar mês anterior para o professor" v-model:visible="displayMaximizable1" :style="{width: '50vw'}" :maximizable="true" :modal="true">

        <div class="p-fluid formgrid grid">

          <div class=" field col-12 md:col-5">
            <label for="servidor">Servidor:</label>
            <pm-Dropdown v-model="infoAutorizacao.servidor_id" :options="professoresALL" optionLabel="nome_todo" placeholder="Selecione o servidor" />
          </div>

          <div class=" field col-12 md:col-3">
            <label for="servidor">Mês liberado:</label>
            <pm-Dropdown v-model="infoAutorizacao.mes" :options="mesesALL" optionLabel="nome" placeholder="Selecione o mês liberado" />
          </div>

          <div class=" field col-12 md:col-4">
            <label for="servidor">Selecione o tempo de liberação(entre 2 e 7 dias)</label>
            <pm-InputNumber id="minmax-buttons"  v-model="infoAutorizacao.qdt_dias" mode="decimal" showButtons :min="2" :max="7" />
          </div>
        </div>
        <template #footer>
          <pm-Button v-if="tipoDeAcao == 1 && infoAutorizacao.servidor_id != '' && infoAutorizacao.mes != undefined" label="Cadastrar informações" icon="pi pi-check" @click="salvarAutorizacao" autofocus />
          <pm-Button v-if="tipoDeAcao == 2 && infoAutorizacao.servidor_id != '' && infoAutorizacao.mes != undefined" label="Editar informações" icon="pi pi-check" @click="editarAutorizacao" autofocus />
        </template>
      </pm-Dialog>

    </section>
  </section>
</template>


<script>
import axios from 'axios';
import { defineComponent } from 'vue'
import { Escola } from "@/class/escolas";
import { LoginInfo } from "@/class/login";
import { Calendario } from "@/class/calendario";
import { Turma } from "@/class/turma";
import { Presenca } from "@/class/presenca";
import { DisciplinaEscolar } from "@/class/disciplina";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Verificar } from "@/class/verificar.js";
import { Servidores } from "@/class/servidores";
import { SeriesEscolar } from "@/class/serie.js";
import { LiberacaoDiario } from "@/class/LiberacaoDiario.js";
import selectCalendario2 from '@/components/presenca/selectCalendario2.vue'
import CalendarioVisualizar from '@/components/presenca/calendarioVisualizar.vue'

import tableProfPresenca01 from '@/components/presenca/tableProfPresenca01.vue'
import editarFundamental1 from '@/components/presenca/editarFundamental1.vue'
import editarFundamental2 from '@/components/presenca/editarFundamental2.vue'

import { Configuracao } from "@/class/configuracao";

import dataMatriculaAlunos from "@/utils/dataMatriculaAlunos.json";
import PresencasLista from "@/components/presenca/presencaALL.vue"

export default defineComponent({
  components: {
    selectCalendario2,
    CalendarioVisualizar,
    tableProfPresenca01,
    editarFundamental1,
    editarFundamental2
  },
  data () {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "ano", label: "Ano", sortable: true },
      { key: 'aluno', label:'Ação'},
    ];
    return {
      mesesALL : [
        {id:'fevereiro', nome: 'Fevereiro'},
        {id:'marco', nome: 'Março'},
        {id:'abril', nome: 'Abril'},
        {id:'maio', nome: 'Maio'},
        {id:'junho', nome: 'Junho'},
        {id:'julho', nome: 'Julho'},
        {id:'agosto', nome: 'Agosto'},
        {id:'setembro', nome: 'Setembro'},
        {id:'outubro', nome: 'Outubro'},
        {id:'novembro', nome: 'Novembro'},
        {id:'dezembro', nome: 'Dezembro'},
      ],
      meses : [
        {numero:'01', palavra: 'janeiro'},
        {numero:'02', palavra: 'fevereiro'},
        {numero:'03', palavra: 'marco'},
        {numero:'04', palavra: 'abril'},
        {numero:'05', palavra: 'maio'},
        {numero:'06', palavra: 'junho'},
        {numero:'07', palavra: 'julho'},
        {numero:'08', palavra: 'agosto'},
        {numero:'09', palavra: 'setembro'},
        {numero:'10', palavra: 'outubro'},
        {numero:'11', palavra: 'novembro'},
        {numero:'12', palavra: 'dezembro'},
      ],
      tipoDeAcao:-1,
      qdt_presenca:1,
      infoAutorizacao: {
        turma_id:-1,
        modulo:"presencas",
        servidor_id:-1,
        qdt_dias:2,
      },
      base_url: axios.defaults.baseURL.replace('/api/v1',''),

      col1:12,
      col2:12,
      col3:3,
      col4:12,
      col5:2,

      abrirComponete:false,
      tipo_segmento:0,
      serie_tipo: 0,
      tabelaDias:[],
      tabelaDiasApenasAnosLetivoColunas:[],
      info:[],
      dataSelecionaFormatoEUA:'',
      presencasAlunosView:[],
      mes_selec:'',
      mes_exib_selec:'',
      todosOsAlunos: [],
      displayMaximizable:false,
      displayMaximizable1:false,
      displayMaximizableEdit2:false,
      selectable:true,
      isLoading:false,
      mesLoading:false,
      porcentagemParaSerAprovado:70,

      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Presenças'},
      ],
      filter:'',
      columns,
      escola:null,
      escola_nome:null,
      escolas:[],
      turma:null,
      turmas: [],
      aluno:0,
      alunos: [],
      alunosPresente: [],
      presenca:0,
      theturma:null,
      professor_id:null,
      escola_id: null,
      segmentoInfo:null,
      serieInfo:null,
      professorInfo: '',
      mes:0,
      disciplina: [],
      presencas: [],
      segmentoALL: [],
      serieALL: [],
      turmaALL: [],
      alunoALL: [],
      disciplina_id: 0,
      disciplina_nome: null,
      expandedRows: [],
      acesso : {
        visualizar:1,
        cadastrar:1,
        editar:1,
        deletar:1,
      },
      professoresALL:[],
      turma_id:null,
      mesAtual:"",
      listaAutorizacaoALL: [],
      carregandoPagina:false,
      tabelaPresencasColunas:[],

      data_final_matricula:null,
      data_inicial_matricula:null,
      funcaoDoUsuario:0,
      selectSerie:'',
      presenca_infinita:0,
    }
  },

   async beforeMount() {
    this.funcaoDoUsuario = parseInt(sessionStorage.getItem("funcaoDoUsuario"));
    let data = await Calendario.calendarioAtual();

    if (data.data['error'] != "Não encontrado!") {
      let dt = await Configuracao.obtemOne(data.data[0].id);
      this.data_final_matricula    = dt.data.data_final_matricula;
      this.data_inicial_matricula  = dt.data.data_inicial_matricula;
    }


    await this.listarEscolas();
    let acesso = await Verificar.AcessoLocal(10);
    this.acesso.visualizar = acesso.visualizar;
    this.acesso.cadastrar = acesso.cadastrar;
    this.acesso.editar = acesso.editar;
    this.acesso.deletar = acesso.deletar;
  },

  methods:{
    async getQdtPresenca(){
      let data = await axios.get('/config/sistema/presenca/'+this.segmentoInfo+'/segmento');
      this.presenca_infinita = data.data.presenca_infinita;
    },
    async editarAutorizacao() {
      try {
        this.infoAutorizacao.servidor_id = this.infoAutorizacao.servidor_id.id;
        this.infoAutorizacao.mes_liberado = this.infoAutorizacao.mes.id;

        let data = await LiberacaoDiario.editarLiberacao(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Salvo com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }

    },
    cadastrarInfo() {
      //cadastar
      this.limparCamposAutorizacao();
      this.tipoDeAcao = 1;
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    async apagarAutorizacao(data) {
      try {
        if (window.confirm("Você realmente deseja apagar a autorização selecionada?")) {
          try {
            let data1 = await LiberacaoDiario.deletarAutorizacao(data.id);
            this.$vaToast.init({
              message: "Removido com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color: 'success' ,
              duration: 2500,
              fullWidth: false,
            })
            this.listarAuto();
          } catch (e) {
            let a = e.response.data.erro;
              setTimeout(() => {
                this.$vaToast.init({
                  message: a,
                  iconClass: 'fa-star-o',
                  position: 'top-right',
                  duration: 3500,
                  fullWidth: false,
                  color: 'danger'
                });
              }, 500);
          }
        }
      }
      catch(e) {
      }
    },
    async editarAutori(data) {
      this.tipoDeAcao = 2;
      this.infoAutorizacao.id = data.id;
      this.infoAutorizacao.turma_id = data.turma_id;
      this.infoAutorizacao.mes = this.mesesALL.find(element => element.id == data.mes_liberado);

      this.infoAutorizacao.servidor_id = this.professoresALL.find(element => element.id == data.servidor_id);

      this.infoAutorizacao.periodo_semestre = "";
      this.displayMaximizable1 = !this.displayMaximizable1;
    },
    limparCamposAutorizacao() {
      this.infoAutorizacao.turma_id = '';
      this.infoAutorizacao.servidor_id =  '';
      this.infoAutorizacao.periodo_semestre = "";
    },
    async buscarProfessores() {
      let data = await Turma.buscarProfessoresDaTurma(this.turma_id);
      this.professoresALL = [];
      for (const el of data.data) {
        el.nome_todo = el.nome + ' ' +  el.sobrenome;
        this.professoresALL.push(el);
      }
    },
    async salvarAutorizacao() {
      this.infoAutorizacao.turma_id = this.turma_id;
      this.infoAutorizacao.servidor_id =   this.infoAutorizacao.servidor_id.id;
      this.infoAutorizacao.periodo_semestre = "";
      this.infoAutorizacao.mes_liberado = this.infoAutorizacao.mes.id;
      try {
        let data = await LiberacaoDiario.CadastrarDiario(this.infoAutorizacao);
        this.$vaToast.init({
          message: "Salvo com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.limparCamposAutorizacao();
        this.listarAuto();
        this.displayMaximizable1 = false;
      }
      catch(e) {
        if(e.response.data.validacao){
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value,
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
          });

        }else{
          let a = [];
          a = e.response.data.erro;

            setTimeout(() => {
              this.$vaToast.init({
                message: a,
                iconClass: 'fa-star-o',
                position: 'bottom-left',
                duration: 3500,
                fullWidth: false,
                color: 'danger'
              });
            }, 500);
        }
        this.limparCamposAutorizacao();
        this.displayMaximizable1 = false;
      }
    },
    async listarAuto() {
      let data = await LiberacaoDiario.visualizarLiberacao("presencas");
      this.listaAutorizacaoALL = [];
      for (const el of data.data) {
        el.nome = el.servidor.nome + ' ' + el.servidor.sobrenome;
        const found = this.mesesALL.find(element => element.id == el.mes_liberado);
        if (found != undefined)
          el.mes = found.nome;
        this.listaAutorizacaoALL.push(el);
      }
    },
    async openModalAutorizacao() {
      this.listarAuto();
      this.displayMaximizableEdit2 = !this.displayMaximizableEdit2;
    },
    voltar(){
      this.$router.push({ name: "turmas"});
    },
    fecharEditarFundamentalUm() {
      this.editarOk = !this.editarOk;
    },
    limparCampos(){
      this.segmentoALL = [];
      this.serieALL = [];
      this.turmaALL = [];

      this.turma_id= null;
      this.segmentoInfo = null;
      this.serieInfo = null;

      this.buscarSegmentoALL();
    },
    async listarEscolas(){
      let esc = await Escola.obtemTodos();

      for (const el of esc.data) {
        let novo = {
          id_: el.id,
          id: el.id,
          logradouro:el.logradouro,
          bairro: el.bairro,
          cep: el.cep,
          cidade: el.cidade,
          cnpj: el.cnpj,
          email: el.email,
          estado: el.estado,
          nome: el.nome,
          numero: el.numero,
          items:[
            {
              label: 'Editar Escola',
              icon: 'pi pi-pencil',
              command: () => {
                 this.editarEscola(el.id)
              }
            },
            {
              label: 'Informações da Escola',
              icon: 'pi pi-search-plus',
              command: () => {
                this.editarInfo(el.id)
              }
            },

          ]
        };
      this.escolas.push(novo);
      };

    },
    async buscarTurma(id,nome) {
      try{
        this.presenca = 0;
        this.alunos = [];
        this.escola = id;
        let anoSelect = sessionStorage.getItem("anoSelecionado");
        let data = await Escola.obtemInfoEscola(id,anoSelect);
        this.turmas = data.data;
      }catch(e){

      }
    },
    async turma_disciplinas(turma) {
      this.turma = turma;
      this.presenca = 0;
      this.alunos = [];
      let data = await Turma.obtemAlunosTurma(this.turma);
      this.alunos = data.data;

      if (this.alunos.length == 0) {
        this.$vaToast.init({
          message: "Nenhum aluno encontrado!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 3000,
          color: "warning",
          fullWidth: false,
        })
      }
    },

    async exibirTabela(dia, semana, mes, mesLetra) {
      this.editarOk = false;
      this.info.dia = dia;
      this.info.mes = mesLetra;
      this.info.semana = semana;
      if (dia.length == 1)
        dia = '0' + dia;
      this.info.dataSelecionada = dia+'/'+mes+'/'+this.info.ano;
      this.dataSelecionaFormatoEUA = this.info.ano+'-'+mes+'-'+dia;
    },

    async presenca_tudo_turma(id) {
      let data = await Turma.obtemPresencaTudo(id);
      this.alunos = data.data;
    },
    async mostrar_presenca(aluno) {
      this.presenca = 1;
      this.aluno = aluno;
      const data = await Turma.obtemUm(this.turma);
      this.theturma = data.data;
      this.mes = 0;
      this.disciplina_id = null,
      this.disciplina = [];
      this.presencas = [];
      this.alunos = [];
      this.listaDisciplina();
    },
    async listarTudo(){
      try{
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);

        if(whoiam.data.funcao != 5){
          this.$router.push({ name: "login"});
        }

      }catch(e){}
    },

    dialogAction() {
      this.alunos = [];
      // this.alunos = this.todosOsAlunos;

      for (const el of this.todosOsAlunos) {
        let dataDeEntradaNaTurma = el.created_at.split('T');
        dataDeEntradaNaTurma = dataDeEntradaNaTurma[0];
         //Verificar se a data que o aluno entrou na turma é menor ou igual a data selecionada
        if (dataDeEntradaNaTurma <= this.dataSelecionaFormatoEUA) {
          this.alunos.push(el);
        }
      }
      if (!this.alunos.length) {
        this.$vaToast.init({
          message: "A turma não tinha alunos na data selecionada!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'warning',
          duration: 3000,
          fullWidth: false,
        });
      }
      else {
        this.displayMaximizable = true;
      }
        // this.displayMaximizable = true;
    },

    async turmaAlunos() {
      this.todosOsAlunos = [];
      let data = await Turma.obtemAlunosTurma(this.turma_id);
      this.todosOsAlunos = data.data;
    },



    async buscarPresencasDisciplina(){
        const date = new Date();
        const today = date.getDate();
        const currentMonth = date.getMonth() + 1;
        this.mesAtual = currentMonth;
        if(currentMonth == 1){await this.montarCalendarioProfessor('janeiro', 'Janeiro');}
        if(currentMonth == 2){await this.montarCalendarioProfessor('fevereiro', 'Fevereiro');}
        if(currentMonth == 3){await this.montarCalendarioProfessor('marco', 'Março');}
        if(currentMonth == 4){await this.montarCalendarioProfessor('abril', 'Abril');}
        if(currentMonth == 5){await this.montarCalendarioProfessor('maio', 'Maio');}
        if(currentMonth == 6){await this.montarCalendarioProfessor('junho', 'Junho');}
        if(currentMonth == 7){await this.montarCalendarioProfessor('julho', 'Julho');}
        if(currentMonth == 8){await this.montarCalendarioProfessor('agosto', 'Agosto');}
        if(currentMonth == 9){await this.montarCalendarioProfessor('setembro', 'Setembro');}
        if(currentMonth == 10){await this.montarCalendarioProfessor('outubro', 'Outubro');}
        if(currentMonth == 11){await this.montarCalendarioProfessor('novembro', 'Novembro');}
        if(currentMonth == 12){await this.montarCalendarioProfessor('dezembro', 'Dezembro');}
    },
    //Receber todos os dia que possuem presencas
    receberTodosOsDiasQueExistemPresencas() {
      let myPresencasDias = new Set();
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        for (var j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (!myPresencasDias.has(this.presencasAlunosView[i].presencas[j].dia)) {
            myPresencasDias.add(this.presencasAlunosView[i].presencas[j].dia);
          }
        }
      }
      return Array.from(myPresencasDias).sort((a, b) => a - b);
    },
    //Buscar todas as presencas que existem em um dia X
    todasAsPresencasQueExistemNoDia(dia) {
      let mySet = new Set();
      let todasAsPresencasNoDia = [];
      for (var i = 0; i < this.presencasAlunosView.length; i++) {
        for (var j = 0; j < this.presencasAlunosView[i].presencas.length; j++) {
          if (!mySet.has(this.presencasAlunosView[i].presencas[j].data) && dia == this.presencasAlunosView[i].presencas[j].dia) {
            todasAsPresencasNoDia.push(this.presencasAlunosView[i].presencas[j]);
            mySet.add(this.presencasAlunosView[i].presencas[j].data);
            //Para add dias para criar as colunas da tabela
            this.tabelaPresencasColunas.push(dia);
          }
        }
      }
      return todasAsPresencasNoDia.sort(function (a, b) {
	        return a.presenca_id - b.presenca_id;
      });
    },
    criarTabelaDePresencasParaCadaAluno(mapOfValues) {
        //Para exibir os dados na tabela de presenca
        for (var i = 0; i < this.presencasAlunosView.length; i++) {
            this.presencasAlunosView[i].presencas1 = [];
        }
        for (const dia of mapOfValues) {
          var presencasDoAluno = this.todasAsPresencasQueExistemNoDia(dia);
          for (var i = 0; i < this.presencasAlunosView.length; i++) {
            for (var j = 0; j < presencasDoAluno.length; j++) {
              //Verificar se o aluno i possue a presenca j
              const found = this.presencasAlunosView[i].presencas.find(element => element.data == presencasDoAluno[j].data);

              if (found != undefined) {
                this.presencasAlunosView[i].presencas1.push(found);
              }
              else {
                this.presencasAlunosView[i].presencas1.push(' ');
              }
            }

          }
        }
    },

    async montarCalendarioProfessor(mes, mesExibir){
      this.carregandoPagina = true;
      this.turmaAlunos();
      this.mes_selec = mes;
      this.mes_exib_selec = mesExibir;
      this.mesLoading = true;

      this.tabelaDias = [];
      this.info.dia = '';
      this.info.semana = '';
      this.info.dataSelecionada = '';
      let anoSelect = sessionStorage.getItem("anoSelecionado");
      this.info.ano = anoSelect;
      if(this.tipo_segmento == 1 || (this.tipo_segmento == 3 && this.serie_tipo == 1) && !this.selectSerie.possui_mais_de_um_professor){
        this.disciplina_id = 1;
        this.disciplina_nome = "Todas as Disciplinas";
      }
      else if (this.tipo_segmento == 0) {
        this.disciplina_id = 2;
        this.disciplina_nome = "Educação Infantil";
      }
      else{
        let busca_disciplina = this.disciplina.find(element => element.id == this.disciplina_id);
        if(busca_disciplina){
          this.disciplina_nome = busca_disciplina.nome;
        }
      }

      //receber mês número
      const found = this.meses.find(element => element.palavra == mes);
      this.mes_numeral = found.numero;

      let novo = {
        disciplina_id: this.disciplina_id,
        mes: mes,
        turma_id: this.turma_id,
        segmento_id: this.segmentoInfo,
        serie: this.serieInfo,
        ano: anoSelect,
      }
      try {
        let data = await Presenca.presencaMontarCalendarioProfessor(novo);

        this.tabelaDias = data.data;
      }
      catch(e) {
        setTimeout(() => {
          this.$vaToast.init({
            message: e.response.data.erro,
            iconClass: 'fa-star-o',
            position: 'top-right',
            duration: 3500,
            fullWidth: false,
            color: 'danger'
          });
        }, 500);
      }
      this.tabelaDiasApenasAnosLetivoColunas = [];
        //Apenas com dia letivo
      for (var i = 0; i < this.tabelaDias.length; i++) {
        if (this.tabelaDias[i].letivo == '0')
            continue;
        if (this.tabelaDias[i].dia.length == 1)
            this.tabelaDias[i].dia = '0' + this.tabelaDias[i].dia;
        this.tabelaDiasApenasAnosLetivoColunas.push(this.tabelaDias[i]);
      }

      this.mesLoading = false;

      //presencas visu
      let nv1 = {
        disciplina_id: this.disciplina_id,
        mes: mes,
        turma_id: this.turma_id,
        ano: anoSelect,
      };
      let data1 = await Presenca.mesesVisu(nv1);

      //console.log("aqi", data1);
      this.presencasAlunosView = [];
      for (const el of data1.data) {
           let info = {
              nome : el.aluno.nome + ' ' + el.aluno.sobrenome,
              evento_saida: el.evento_saida,
              presencas : [],
              cntPresencas:0,
              data_entrada_turma: el.data_entrada_na_turma,
              data_entrada_turma_br: el.data_entrada_na_turma_br,
              entrou_no_prazo_certo:false,
           };

           //verificar se ele entrou no prazo certo na turma
           if(this.data_final_matricula == null ){
              if (el.data_entrada_na_turma >= dataMatriculaAlunos[0].data_inicial_matricula && el.data_entrada_na_turma <= dataMatriculaAlunos[0].data_final_matricula) {
                  info.entrou_no_prazo_certo = true;
              }
           }else{
              let dfm = this.data_final_matricula.split(" ");
              let dim = this.data_inicial_matricula.split(" ");

              this.data_inicial_matricula = dim[0];
              this.data_final_matricula = dfm[0];

              if (el.data_entrada_na_turma >= this.data_inicial_matricula && el.data_entrada_na_turma <= this.data_final_matricula) {
                info.entrou_no_prazo_certo = true;
              }
           }

           let cntPresenca = 0;
           for (const el1 of el.presenca) {
              let novo = {
                presenca_id: el1.id,
                aluno_id: el.aluno.id,
                data: el1.data,
                disciplina_id: el1.disciplina_id,
                escola_id: el1.escola_id,
                id_presenca: el1.id,
                justificacao_conteudo: el1.justificacao_conteudo,
                justificada_falta: el1.justificada_falta,
                presenca: el1.presenca,
                professor_id: el1.professor_id,
                segmento_id: el1.segmento_id,
                serie_id: el1.serie_id,
                turma_id: el1.turma_id,
                dia:''
              }
              //contar presencas
              info.cntPresencas += el1.presenca;
              //receber dia
              let receberDia = novo.data.split('-');
              receberDia = receberDia[2].split(" ");
              novo.dia = receberDia[0];
              info.presencas.push(novo);
           }

        this.presencasAlunosView.push(info);
        //professor
      }

      this.tabelaPresencasColunas = [];
      this.criarTabelaDePresencasParaCadaAluno(this.receberTodosOsDiasQueExistemPresencas());
      this.presencasAlunosView[0].tableColuna =  this.tabelaPresencasColunas;

      this.carregandoPagina = false;
      this.abrirComponete = true;
      //abrir modal
       return true;
    },

    marcarPresenca(n,item){
      item.presente = n;
    },

    marcarPresencaFundamentalDois(chamada, n, item){
      if (chamada == 1)
        item.presenca_1 = n;
      else
        item.presenca_2 = n;
    },

    async presencaEnviar(){
      try {
        this.alunosPresente = [];
        //array dos alunos
        for (var i = 0; i < this.alunos.length; i++) {
          if (this.alunos[i].presente == undefined) {
            alert("Adicione as informações de presença para todos os alunos!");
            return false;
          }
          let novo = {
            aluno_id: this.alunos[i].id,
            presenca:  this.alunos[i].presente,
            justificada_falta: 0,
            justificacao_conteudo:''
          }
          this.alunosPresente.push(novo);
        }

        //2022-05-26 10:38:11
        //Receber a hora atual
        let now = new Date;
        let horas = String(now.getHours());
        let minutos = String(now.getMinutes());
        let segundos = String(now.getSeconds());
        if (horas.length == 1)
          horas = "0" + horas;
        if (minutos.length == 1)
          minutos = "0" + minutos;
        if (segundos.length == 1)
          segundos = "0" + segundos;

        let horaAtual = horas + ':'  + minutos + ':' + segundos;

        //Data selecionada pelo usuário
        let dataFormatoUSA = this.info.dataSelecionada.split("/");
        let dataAtual =  dataFormatoUSA[2] + '-' + dataFormatoUSA[1] + '-' + dataFormatoUSA[0];
        let dataEHora = dataAtual + ' ' + horaAtual;


        //Só um professor
        if ((this.tipo_segmento == 1 &&  !this.selectSerie.possui_mais_de_um_professor) || (this.tipo_segmento == 3 && this.selectSerie.tipo == 1)) {

          let profTurma = await Turma.buscarProfessoresDaTurma(this.turma_id);
          if (profTurma.data.login_id == undefined) {
            this.professor_id = 0;
          }
          else {
            this.professor_id = profTurma.data.login_id;
          }
        }//mais de um professor
        else {
          let onlyOneProf;
          if(!this.tipo_segmento){
            onlyOneProf = await Turma.verificaNaDisciplinaProfessor(2, this.turma_id);
          }else{
            onlyOneProf = await Turma.verificaNaDisciplinaProfessor(this.disciplina_id, this.turma_id);
          }
          if (onlyOneProf.data == "Professor não Encontrado!") {
            this.$vaToast.init({
              message: "A operação não foi realizada, pois a disciplina selecionada não possui professor!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color:'warning',
              duration: 4000,
              fullWidth: false,
            });
            return false;
          }
          else {
            this.professor_id = onlyOneProf.data.professor.id;
          }
        }


        //informacoes importantes
        let info = {
          segmento_id: this.segmentoInfo,
          escola_id: this.escola_id,
          turma_id: this.turma_id,
          serie_id: this.serieInfo,
          professor_id: this.professor_id,
          disciplina_id:  this.disciplina_id,
          data: dataEHora,
          qdt_presenca: this.qdt_presenca,
        }
        //fechar modal
        this.displayMaximizable = false;
        let data = await Presenca.criarPresencaDosAlunos(this.alunosPresente, info);
        this.qdt_presenca = 1;
        this.$vaToast.init({
          message: "Presença enviada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });

        this.turmaAlunos();

        this.montarCalendarioProfessor(this.mes_selec,this.mes_exib_selec);
        this.isLoading = false;

      }catch(e) {
        //console.log(e);
         this.$vaToast.init({
          message: "Error ao enviar presença!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
        this.isLoading = false;
      }

    },

    async EditarPresencaDaTurma(alunos) {
      try {
        this.alunosPresente = [];
        for (var i = 0; i < alunos.length; i++) {
          if (alunos[i].presente != undefined) {
            let novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome:  alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presente,
              presenca_2: null,
              ano: this.info.ano,
              mes: this.info.mes,
              dia: this.info.dia
            }
            this.alunosPresente.push(novo);
          }
          else {
            let novo = {
              aluno_id: alunos[i].aluno_id,
              id: alunos[i].id,
              nome:  alunos[i].nome,
              turma_id: alunos[i].turma_id,
              presenca_1: alunos[i].presenca_1,
              presenca_2: alunos[i].presenca_2,
              ano: this.info.ano,
              mes: this.info.mes,
              dia: this.info.dia
            }
            this.alunosPresente.push(novo);
          }
        }

        //Gerar presencas

        let pf = await Servidores.buscarServidorIdLogin(this.st_professor_id);
        let data = await Presenca.EditarPresencaDaTurma(this.alunosPresente, pf.data.id,
        this.disciplinaSelecionadaPeloProf.turma_disciplina_id, this.infoParaEnviar.turma_id,this.qdt_presenca);

        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });
        this.editarOk = false;
      }
      catch(e) {
        this.$vaToast.init({
          message: "Problema ao editar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
      }
    },

    async buscarSegmentoALL() {
      let data = await SegmentoEscolar.obtemTodos();
      this.segmentoALL = [];
      for (const el of data.data) {
        //if (el.id == 5)
          //continue;
        this.segmentoALL.push(el);
      }
    },

    async listaSerie (id) {
      this.turma_id = null;
      this.serieInfo = null;

      let dataseg = await SegmentoEscolar.obtemUm(id);
      this.tipo_segmento = dataseg.data.tipo;

      let data = await SegmentoEscolar.listarSegmentosPorSerie(id);

      this.serieALL = data.data;
      return this.seriesALL;
    },


    async listaTurmas(){
      let play = {
        'escola':this.escola_id,
        'serie': this.serieInfo,
        'segmento': this.segmentoInfo,
      };

      let data = await Turma.pesqTurmaPorSerieSegmento(play);
      this.turmaALL = data.data;

      let dat = await SeriesEscolar.obtemUm(this.serieInfo);
      this.selectSerie = dat.data;
      if(this.tipo_segmento != 2){
        this.serie_tipo = dat.data.tipo;
      }else{
        this.serie_tipo = 0;
      }

      await this.getQdtPresenca();
    },

    async listarAlunosdaTurma(){
      let data = await Turma.alunoEmUmaTurma(this.turma);
      this.alunoALL = data.data;
    },

    async listaDisciplina(){
      let data = await DisciplinaEscolar.obtemTodosSemId1Turma(this.turma);
      this.disciplina = [];
      for (const el of data.data) {
        if(el.na_turma == 1){
          let novo = {
            id: el.id,
            nome: el.nome,
            status: el.status
          }
          this.disciplina.push(novo);
        }
      }
    },

    async init(){
      try{
        let calendario = await Calendario.calendarioAtual();

        if (this.segmento_tipo == 1 || (this.segmento_tipo == 3 && this.serie_tipo == 1)) {
          this.porcentagemParaSerAprovado = calendario.data.porcent_fund1_falta;
        }
        else {
          this.porcentagemParaSerAprovado = calendario.data.porcent_outros_falta;
        }
      }catch(e){
      }
    },
  },
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
</style>

<style>
 .th_prf{
    background-color: #0067a7 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
 }
 .th_prf1{
    background-color:  #2e92d1 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
 }
 .tbody_prf{
    border-top: 0px solid currentColor !important;
 }
</style>
